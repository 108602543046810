import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Spin, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import CoInsuranceService from "services/coInsuranceService";
import CreateCoInsuranceRequest from "api/request/createCoInsuranceRequest";
import { toast } from "react-toastify";
import ActionType from "api/acionType";
import FormRegisterCoInsurance from "forms/addCoInsuranceForm";
import FormEditCoInsurance from "forms/editCoInsuranceForm";
import NumberFormat from "react-number-format";
import { Card } from "reactstrap";


export default function Co_Assurance(props) {
    let formRegisterCoInsurance = new FormRegisterCoInsurance();
    let formEditCoInsurance = new FormEditCoInsurance();
    const [form] = Form.useForm();
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });
    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    useEffect(() => {
        getAllCoAssurances();
    }, [])

    const [state, setState] = useState({
        coInsurances: [],
        dataSource: [],
        isCoInsuranceLoading: true,
        isCoInsuranceTableUpdateContent: false,

        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,
    });

    const getAllCoAssurances = () => {

        (new CoInsuranceService()).getAllCoInsurances((data) => {
            setState(state => ({ ...state, coInsurances: data, dataSource: data, isCoInsuranceLoading: false, isCoInsuranceTableUpdateContent: false }));
        }, (error) => { });

    }

    const onDrawerOpen = (value, actionType) => {
        if (value) {
            formEditCoInsurance = formEditCoInsurance.fromJson({
                code: value?.code,
                title: value?.title,
                phone: value?.phone,
                email: value?.email,
                description: value?.description,
            });

            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditCoInsurance });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...formRegisterCoInsurance });
        }

        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: true, actionType: actionType }));
    }

    // TODO-OK: Ajout et edition d'une co-assurance
    const onRegisterOrEditCoInsuranceSubmit = (action) => {
        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    let formRegisterFields = formRegisterCoInsurance.toJson(fields);
                    let createCoInsuranceRequest = (new CreateCoInsuranceRequest()).fromJson(formRegisterFields);

                    (new CoInsuranceService()).createCoInsurance(createCoInsuranceRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, isCoInsuranceTableUpdateContent: true }));
                        getAllCoAssurances();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Co-assurance ajoutée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:

                    let formEditFields = { ...formEditCoInsurance, ...fields };
                    let editCoInsuranceRequest = (new CreateCoInsuranceRequest()).fromJson(formEditFields);

                    new CoInsuranceService().editCoInsurance(editCoInsuranceRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, isGroupementLoading: true, isUserTableUpdateContent: true }));
                        getAllCoAssurances();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Co-assurance modifiée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            toast.error("Co-assurance non enregistrée");
        })
        setState(state => ({ ...state, loading: false }));
    }

    //TODO-OK: <Recherche d'un co assureur>
    function onSearchCoInsurance(e) {
        const val = e?.target?.value?.toLowerCase();
        const temp = state.coInsurances?.filter((d) => {
            return d?.code?.toLowerCase().indexOf(val) !== -1 ||
                d?.title?.toLowerCase().indexOf(val) !== -1 ||
                !val
        });
        setState(state => ({ ...state, dataSource: temp }))
    }

    const columns = [

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 100,
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            width: 250
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            

        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            
        },
        {
            title: "Telephone",
            dataIndex: "phone",
            key: "phone",
            width: 150
        },

        /* {
            title: "Etat",
            dataIndex: "statut",
            key: "statut",
            width: 50,
            render: (item, data) => (
                <div>
                    <Switch defaultChecked={data.statut} onChange={() => onChange(data)} size="small" />
                </div>
            )
        }, */
        {
            title: "Actions",
            dataIndex: "action",
            width: 100,
            key: "action",
            render: (item, coInsurance) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => onDrawerOpen(coInsurance, ActionType.EDIT)} />
                    {/* <DeleteOutlined style={{ color: '#E5AC28', cursor: 'pointer', marginLeft: 10 }} onClick={() => {  }} /> */}
                </>

            )
        }
    ];

    return (
        <Card>
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des co-assurances</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Input placeholder="Recherche: Code, titre" style={{ width: 350, marginRight: 10 }} onChange={onSearchCoInsurance} />
                        <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                            <FileAddOutlined />
                            Ajouter une co-assurance
                        </Button>
                    </div>
                </div>
                <br />
                {!state.isCoInsuranceLoading ? (
                    <Table
                        dataSource={state.dataSource}
                        columns={columns}
                        loading={state.isCoInsuranceTableUpdateContent}
                    />
                ) : (<Spin />)}

                {drawerVisibility.visibility && (
                    <Drawer
                        title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'une co-assurance" : "Modifier la co-assurance"}
                        width={720}
                        onClose={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })) }}
                        visible={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={() => onRegisterOrEditCoInsuranceSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={formRegisterCoInsurance}
                        >
                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Code (Optionnel)"
                                        name="code"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Entrez le code de la co-assurance svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le code de la co-assurance" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Titre"
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Titre de la co-assurance',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Titre de la co-assurance" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Contact"
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le contact du co-assureur SVP!',
                                            },
                                        ]}
                                    >
                                        <NumberFormat format="+### ##-##-##-##-##" mask="_" style={{ height: 'auto', minWidth: 273, borderRadius: 4, border: '1px solid #D8DADB', width: '100%', padding: '4px 11px' }} placeholder="Entrer le contact" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le mail du co-assureur SVP !',
                                                type: "email"
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Mail" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Entrez une description svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder='Description de la co-assurance' size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}

                {/**********DELETE ALERTE ****** */}
                {false && (
                    <Modal
                        title="Supprimer un partenaire !!!"
                        visible={false}
                        onOk={() => { }}
                        onCancel={() => { }}
                        okText="Confirmer"
                        cancelText="Annuler"
                    >
                        <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette agence ?</h3>
                    </Modal>
                )}
            </div>
        </Card>
    )
}