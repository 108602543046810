import { EditOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Row, Spin, Switch, Table, Select, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import UserService from "services/userService";
import EntitiesService from "services/entitiesService";
import SignUpRequest from "api/request/signUpRequest";
import ProfileService from "services/profileService";
import NumberFormat from "react-number-format";
import FormRegisterUser from "forms/registerUserForm";
import ActionType from "api/acionType";
import { toast } from "react-toastify";
import FormEditUser from "forms/editUserForm";
import { Card } from "reactstrap";

import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import {dictionary, adjacencyGraphs} from "@zxcvbn-ts/language-common";
import {translations} from "@zxcvbn-ts/language-fr";

import PasswordIndicator from "views/pages/common/PasswordIndicator";
import PasswordSuggestions from "views/pages/common/PasswordSuggestions";

const options = {
    translations: translations,
    graphs: adjacencyGraphs,
    dictionary: {
      ...dictionary
    }
  };
  zxcvbnOptions.setOptions(options);

const Utilisateurs=(props)=> {
    const [password, setPassword] = useState("");
    const [indicator, setIndicator] = useState({ score: -1, feedback: null });

    let formRegisterUser = new FormRegisterUser();
    let formEditUser = new FormEditUser();
    const Option = Select;
    const [form] = Form.useForm();
    
    const [modifPasswordChecked, setModifPasswordChecked] = useState(false);
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });

    const [state, setState] = useState({
        users: [],
        isUsersLoading: true,
        isUserTableUpdateContent: false,
        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,
        entities: [],
        isEntitiesLoading: false,
        roles: [],
        isRolesLoading: false,
        profiles: [],
        isProfilesLoading: false,
        dataSource: [],
        isStatusEditing: false
    });

    useEffect(() => {
        getAllUsers();
    }, [])

    useEffect(() => {
        if (password === "") return;
    
        setIndicator(zxcvbn(password));
      }, [password]);

    const getEntities= ()=> {
        setState(state => ({ ...state, isEntitiesLoading: true }));
        EntitiesService.readAllEntities((data)=> {
            setState(state => ({ ...state, entities: data, isEntitiesLoading: false }));
        }, (error)=> {})
    }

    const getAllUsers= ()=> {
        (new UserService()).getAllUser((data)=> {
            setState(state => ({ ...state, users: data, dataSource: data, isUsersLoading: false, isUserTableUpdateContent: false }));
        }, (error)=>{})
    }

    const getProfiles= ()=> {
        setState(state => ({ ...state, isProfilesLoading: true }));
        (new ProfileService()).readAllProfiles((data)=> {
            setState(state => ({ ...state, profiles: data, isProfilesLoading: false }));
        }, (error)=>{})
    }

    const onChangeUserStatus=(user)=> {
        setState(state => ({ ...state, isStatusEditing: true }));
        (new UserService()).editUserStatus({query: {code: user?.code, s: !user?.status}}, (data)=> {
            setState(state => ({ ...state, isUserTableUpdateContent: true, isStatusEditing: false }));
            getAllUsers();
            toast.success(`Status de ${user?.name} modifié avec succès `, {style: {background: 'primary'}});
        }, (error)=> {
            setState(state => ({ ...state, isStatusEditing: false }));
            toast.error(error);
        });
    }

    const onDrawerOpen = (value, actionType)=> {
        if(value) {
            formEditUser = formEditUser.fromJson({
                code: value?.code,
                name: value?.name,
                username: value?.username,
                phone: value?.phone,
                email: value?.email,
                password: value?.password,
                entitiesCode: value?.entities?.code,
                entitiesTitle: value?.entities?.title,
                profileRef: value?.profile.ref,
                profileTitle: value?.profile.title
            });
            console.log(formEditUser)
            form.setFieldsValue({...form.getFieldsValue(), ...formEditUser});
        }
        else {
            form.setFieldsValue({...form.getFieldsValue(), ...formRegisterUser});
        }
        
        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: true, actionType: actionType}));
        getEntities();
        getProfiles();
    }

    const onRegisterOrEditUsertSubmit=(action)=> {

        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields)=> {
            switch(action) {
                case ActionType.REGISTER:
                    let formRegisterFields = formRegisterUser.toJson(fields);
                    let signUpRequest = (new SignUpRequest()).fromJson(formRegisterFields);

                    (new UserService()).createUser(signUpRequest, (data)=> {
                        form.resetFields();
                        setState(state => ({ ...state, isUserTableUpdateContent: true }));
                        getAllUsers();
                        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                        toast.success("Utilisateur ajouté avec succès", {style: {background: 'primary'}});
                    }, (error)=> {
                        toast.error(error);
                    });
                break;

                case ActionType.EDIT:
                    
                    let formEditFields = {...formEditUser, ...fields};
                    let editUserRequest = (new SignUpRequest()).fromJson(formEditFields);
                    
                    new UserService().editUser({query:{code: form.getFieldValue("code"), pwd: modifPasswordChecked}}, editUserRequest, (data)=> {
                        form.resetFields();
                        setState(state => ({ ...state, isGroupementLoading: true, isUserTableUpdateContent: true }));
                        getAllUsers();
                        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                        toast.success("Utilisateur modifié avec succès", {style: {background: 'primary'}});
                    }, (error)=> {
                        toast.error(error);
                    });
                break;

                default:
                    setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                break;
            }
        }).catch((error)=> {
            toast.error("Utilisation non enregistré");
        })
        setState(state => ({ ...state, loading: false })); 
    }

    //<Recherche d'un utilisateur>
    const onSearchUser= (e)=> {
        const val = e?.target?.value?.toLowerCase();
        const temp = state.users?.filter((d)=> {
            return d?.code?.toLowerCase().indexOf(val) !== -1 ||
            d?.name?.toLowerCase().indexOf(val) !== -1 ||
            d?.username?.toLowerCase().indexOf(val) !== -1 ||
            d?.email?.toLowerCase().indexOf(val) !== -1 ||
            d?.phone?.toLowerCase().indexOf(val) !== -1 ||
            d?.entities.code?.toLowerCase().indexOf(val) !== -1 ||
            d?.entities.title?.toLowerCase().indexOf(val) !== -1 || 
            !val
        });
        setState(state => ({...state, dataSource: temp}))
    }

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 100
        },
        {
            title: "Nom complet",
            dataIndex: "name",
            key: "name",
            // width: 200
        },
        {
            title: "Login",
            dataIndex: "username",
            key: "username"
        },
        {
            title: "Telephone",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Partenaire",
            dataIndex: "entities",
            key: "entities",

            render: (item, user) => (
                <div>
                    {user.entities.title}
                </div>

            )
        },
        {
            title: "Code Part",
            dataIndex: "entities",
            key: "entities",
            width: 100,
            render: (item, user) => (
                <div>
                    {user.entities.code}
                </div>

            )
        },
        {
            title: "profile",
            dataIndex: "profile",
            key: "profile",
            render: (item, user) => (
                <div>
                    {user.profile.title}
                </div>
            )
        },
        /* {
            title: "Roles",
            dataIndex: "roles",
            key: "roles",
            render: (item, user) => (
                <div>
                    {user.profile.roles.join(", ").toLowerCase()}
                </div>
            )
        }, */
        {
            title: "Action",
            dataIndex: "action",
            width: 100,
            fixed: 'right',
            key: "action",
            render: (item, user) => (
                <>
                    <div style={{display:"flex", justifyContent: "space-between"}}>
                        <div>
                            <Switch checked={user?.status} onChange={() => onChangeUserStatus(user)} size="small" loading={state.isStatusEditing}/>
                        </div>
                        <div>
                        <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginLeft: 10 }} onClick={() => onDrawerOpen(user, ActionType.EDIT)} />
                        </div>
                        {/* <DeleteOutlined style={{ color: '#E5AC28', cursor: 'pointer', marginLeft: 10 }} onClick={() => { setDel(true); setState(state => ({ ...state, itemId: data.id })) }} /> */}
                        
                        {/* <EyeOutlined style={{ color: '#40E070', cursor: 'pointer', marginLeft: 10 }} /> */}
                    </div>
                    
                </>

            )
        }
    ];

    const { score } = indicator;
    const feedback = indicator ? indicator.feedback : undefined;

    return (
        <Card>
            <div style={{padding:20}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des utilisateurs</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Input placeholder="Recherche: code, nom complet, telephone, email, partenaire ..." style={{ width: 350, marginRight: 10 }} onChange={onSearchUser} />
                        <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)}>
                            <FileAddOutlined />
                            Ajouter un utilisateur
                        </Button>
                    </div>
                </div>
                <br />
                {!state.isUsersLoading ? (
                    <Table 
                        dataSource={state.dataSource} 
                        columns={columns} 
                        pagination={{ position: ["topRight"], pageSize: 5 }}
                        scroll={{ x: 1400 }}  
                        loading={state.isUserTableUpdateContent}
                    />
                ) : (<Spin />)}

                {drawerVisibility.visibility && (
                    <Drawer
                        title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'un utilisateur" : "Modifier l'utilisateur"}
                        width={720}
                        onClose={() => {setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}))}}
                        open={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={()=> onRegisterOrEditUsertSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={formRegisterUser}
                        >
                            <Row gutter={8} >
                                {
                                    drawerVisibility.actionType === ActionType.EDIT ? (
                                        <Col className="gutter-row" xs={24} md={12} style={{display: "none"}}>
                                            <Form.Item
                                                label="code"
                                                name="code"
                                            >
                                                <Input  size="middle" />
                                            </Form.Item>
                                        </Col>
                                    ): null
                                }
                                <Col className="gutter-row" xs={12} md={16}>
                                    <Form.Item
                                        label="Nom complet"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le nom complet de l\'utilisateur svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le nom complet de l'utilisateur" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={8}>
                                    <Form.Item
                                        label="Login"
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le login de \'utilisateur svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le login de l'utilisateur"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Contact"
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le contact de l\'utilisateur svp !',
                                            },
                                        ]}
                                    >
                                        <NumberFormat format="+### ##-##-##-##-##" mask="_" style={{ height: 'auto', minWidth: 273, borderRadius: 4, border: '1px solid #D8DADB', width: '100%', padding:'4px 11px' }} placeholder="Entrez le contact de l'utilisateur" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le mail de l\'utilisateur svp !',
                                                type: "email"
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Mail de l'utilisateur" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                drawerVisibility.actionType === ActionType.EDIT &&
                                (
                                    <Row gutter={8}>
                                        <Col className="gutter-row" xs={12} md={24}>
                                            <p>
                                                <Checkbox checked={modifPasswordChecked}  onChange={(e)=> {console.log(e); setModifPasswordChecked(e.target.checked)}}>
                                                    Modifier mot de passe
                                                </Checkbox>
                                            </p>
                                        </Col>
                                    </Row>
                                )
                            }

                            {
                            drawerVisibility.actionType === ActionType.REGISTER || (drawerVisibility.actionType === ActionType.EDIT && modifPasswordChecked === true)  ? (
                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={12} md={12}>
                                        <Form.Item
                                            label="Mot de passe"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Le mot de passe svp !'
                                                },
                                            ]}
                                        >
                                            <div>
                                                <Input placeholder="Mot de passe" type="password" onChange={(event) => setPassword(event.target.value)} />
                                                {password !== "" && <PasswordIndicator score={score} />}
                                                {feedback && feedback?.warning?.length > 0 && (<PasswordSuggestions suggestions={feedback?.suggestions} />)}
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" xs={12} md={12}>
                                        <Form.Item
                                            label="Repetez le mot de passe"
                                            name="password2"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Repetez le mot de passe svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Mot de passe" type="password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : null
                            }

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Entité"
                                        name="entitiesCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Choisissez une entité svp !',
                                            },
                                        ]}
                                    >
                                        {
                                            state.isEntitiesLoading ? (<Spin />) :
                                            <SelectWithFilter placeholder="Choisissez l'entité " size="middle">
                                                {
                                                    state.entities.map((entity, index)=> <Option value={entity.code} key={index}>{entity.title}</Option>)
                                                }
                                            </SelectWithFilter>
                                        }
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Profile"
                                        name="profileRef"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le choix du profile svp !'
                                            },
                                        ]}
                                    >
                                        {
                                            state.isProfilesLoading ? (<Spin />) :
                                            <SelectWithFilter placeholder="Choisissez le profile " size="middle">
                                                {
                                                    state.profiles.map((profile, index)=> <Option value={profile.ref} key={index}>{profile.title}</Option>)
                                                }
                                            </SelectWithFilter>
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => {setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false})); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                {drawerVisibility.actionType === ActionType.REGISTER ?  "Enregistrer" : "Editer" }
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}
            </div>
        </Card>
    )
}

export default Utilisateurs;