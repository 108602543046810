import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Select, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import EntitiesService from "services/entitiesService";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import ContributorService from "services/contributorService";
import CreatePartnerRequest from "api/request/createPartnerRequest";
import NumberFormat from "react-number-format";
import ActionType from "api/acionType";
import FormRegisterPartner from "forms/addPartnerForm";
import FormEditPartner from "forms/editPartnerForm";
import { toast } from "react-toastify";
import { Card } from "reactstrap";

export default function SettingPartenaire(props) {
    let formRegisterPartner = new FormRegisterPartner();
    let formEditPartner = new FormEditPartner();
    const [form] = Form.useForm();
    const Option = Select;
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });
    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    const [state, setState] = useState({
        partners: [],
        partnerSelected: {},
        isPartnersLoading: false,
        typesPartnership: [],
        isTypePartnershipLoading: false,
        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,
        entitiesNatures: [],
        isEntitiesnaturesLoading: false,
        dataSource: []
    });

    useEffect(() => {
        getAllPartner();
    }, [])

    const getAllPartner= ()=> {
        EntitiesService.readAllEntities((data)=> {
            setState(state => ({ ...state, partners: data, dataSource: data, isPartnersLoading: false }));
        }, (error)=> {})
    }

    const getAllEntitiesNatures=()=> {
        setState(state => ({ ...state, isEntitiesnaturesLoading: true }));
        EntitiesService.readAllEntitiesNatures((data)=> {
            setState(state => ({ ...state, entitiesNatures: data || [],  isEntitiesnaturesLoading: false }));
        }, (error)=> {})
    }

    const getAllContributors= (entitieNatureCode)=> {
        setState(state => ({ ...state, isTypePartnershipLoading: true }));
        const options = {
            path: {
                natureCode: entitieNatureCode
            }
        }
        ContributorService.readAllContributors(options, (data)=> {
            setState(state => ({ ...state, typesPartnership: data, isTypePartnershipLoading: false }));
        }, (error)=> {})
    }

    // TODO-OK: <Enregistrement et edition d'un groupement>
    const onRegisterOrEditPartnerSubmit = (action)=> {
        setState(state => ({ ...state, loading: true }));

        form.validateFields().then((fields)=> {
            switch(action) {
                case ActionType.REGISTER:
                    let formRegisterFields = formRegisterPartner.fromJson(fields);
                    let createPartnerRequest = (new CreatePartnerRequest()).fromJson(formRegisterFields);
        
                    (new EntitiesService()).createEntitie(createPartnerRequest, ()=> {
                        form.resetFields();
                        setState(state => ({ ...state, isPartnersLoading: true }));
                        getAllPartner();
                        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                        toast.success("Partenaire ajouté avec succès", {style: {background: 'primary'}});
                    }, (error)=> {
                        toast.error(error);
                    });
                break;

                case ActionType.EDIT:
                    
                    let formEditFields = {...formEditPartner, ...fields};
                    let editPartnerRequest = (new CreatePartnerRequest()).fromJson(formEditFields);

                    new EntitiesService().editEntitie(editPartnerRequest, (data)=>{
                        form.resetFields();
                        setState(state => ({ ...state, isPartnersLoading: true }));
                        getAllPartner();
                        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                        toast.success("Parénaire modifié avec succès", {style: {background: 'primary'}});
                    }, (error)=> {
                        toast.error(error);
                    });
                break;

                default:
                    setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                break;
            }
        }).catch((error)=> {
            toast.error("Parténaire non enregistré");
        })
        
        setState(state => ({ ...state, loading: false }));
    }

    const onSearchPartener= (e)=> {
        const val = e?.target?.value?.toLowerCase();
        const temp = state.partners?.filter((d)=> {
            return d?.code?.toLowerCase()?.indexOf(val) !== -1 ||
            d?.title?.toLowerCase()?.indexOf(val) !== -1 ||
            d?.description?.toLowerCase()?.indexOf(val) !== -1 ||
            d?.email?.toLowerCase()?.indexOf(val) !== -1 || 
            d?.contributorTitle?.toLowerCase().indexOf(val) !== -1 ||
            (new Date(d.createdAt).toLocaleDateString())?.toLowerCase()?.indexOf(val) !== -1 ||
            !val
        });
        setState(state => ({...state, dataSource: temp}))
    }

    const onDrawerOpen= (value, actionType)=> {
        setState(state => ({ ...state, isTypeGroupementLoading: true }));
        if(value) {
            
            formEditPartner = formEditPartner.fromJson({
                ref: value?.ref,
                code: value.code,
                natureCode: value?.nature?.code,
                contributorRef: value?.contributorRef,
                contributorTitle: value?.contributorTitle,
                title: value?.title,
                phone: value?.phone,
                email: value?.email,
                description: value?.description,
                address: value?.address
            });
            getAllContributors(value?.nature?.code);
            setState(state => ({ ...state, partnerSelected: formEditPartner }));
            form.setFieldsValue({...form.getFieldsValue(), ...formEditPartner});
        }
        else {
            form.setFieldsValue({...form.getFieldsValue(), ...formRegisterPartner});
        }
        
        setState(state => ({ ...state, isPartnersLoading: false }));
        getAllPartner();
        getAllEntitiesNatures();
        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: true, actionType: actionType}));
    }

    const onEntitiesNatureSelected=(natureCode)=> {
        getAllContributors(natureCode);
    }

    const columns = [
        {
            title: "Date de creation",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt,item)=> (
                <div>
                    {(new Date(createdAt).toLocaleDateString()) + " à " + (new Date(createdAt).toLocaleTimeString()) }
                </div>
            )
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code"
        },
        {
            title: "Libelle",
            dataIndex: "title",
            key: "title"
        },
        {
            title: "Nature",
            dataIndex: "nature",
            key: "nature",
            render: (item, entities) => (
                <>
                    {entities?.nature?.title}
                </>

            )
        },
        {
            title: "Type part.",
            dataIndex: "contributorTitle",
            key: "contributorTitle"
        },
        {
            title: "Telephone",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Adresse",
            dataIndex: "address",
            key: "address"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, entities) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => { onDrawerOpen(entities, ActionType.EDIT) }} />
                </>

            )
        }
    ];

    return (
        <Card>
            <div style={{padding:20}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des partenaires</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Input placeholder="Recherche" style={{ width: 200, marginRight: 10 }} onChange={onSearchPartener} />
                        <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)}>
                            <FileAddOutlined />
                            Ajouter un partenaire
                        </Button>
                    </div>
                </div>
                <br />
                {!state.isPartnersLoading ? (
                    <Table 
                        dataSource={state.dataSource} 
                        columns={columns}
                        pagination={{ position: ["topRight"], pageSize: 10 }} 
                        />
                ) : (<Spin />)}

                {drawerVisibility.visibility && (
                    <Drawer
                        title="Enregistrement de partenaire"
                        width={720}
                        onClose={() => {setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}))}}
                        visible={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={()=> onRegisterOrEditPartnerSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={formRegisterPartner}
                        >
                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Code"
                                        name="code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le code du partenaire svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le code du partenaire" disabled={ActionType.EDIT === drawerVisibility.actionType}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Libelle"
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le libelle du partenaire svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Libelle du partenaire" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Nature"
                                        name="natureCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Choisissez la nature svp !',
                                            },
                                        ]}
                                    >
                                        {
                                            state.isEntitiesnaturesLoading ? (<Spin />) :
                                            <SelectWithFilter placeholder="Nature " size="middle" onChange={onEntitiesNatureSelected}>
                                                {
                                                    state.entitiesNatures.map((entitieNature, index)=> {
                                                        return (<Option value={entitieNature?.code} key={index}>{entitieNature?.title}</Option>)
                                                    })
                                                }
                                            </SelectWithFilter>
                                        }
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Type de partenariat"
                                        name="contributorRef"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Choisissez le type de partenariat svp !',
                                            },
                                        ]}
                                    >
                                        {
                                            state.isTypePartnershipLoading ? (<Spin />) :
                                            <SelectWithFilter placeholder="Type de partenariat " size="middle">
                                                {
                                                    state.typesPartnership.map((typePartnership, index)=> {
                                                        return (<Option value={typePartnership.ref} key={index}>{typePartnership.title}</Option>)
                                                    })
                                                }
                                            </SelectWithFilter>
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Telephone"
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le telephone du partenaire svp !',
                                            },
                                        ]}
                                    >
                                        <NumberFormat format="+### ##-##-##-##-##" mask="_" style={{ height: 'auto', minWidth: 273, borderRadius: 4, border: '1px solid #D8DADB', width: '100%', padding:'4px 11px' }} placeholder="Entrez le telephone du partenaire" addonBefore={<PhoneOutlined />} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'L\'email du partenaire svp !',
                                                type: "email"
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez l'email du partenaire" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Description du partenaire svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder="Entrez la description du partenaire" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Adresse"
                                        name="address"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Adresse du partenaire" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => {setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false})); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    {drawerVisibility.actionType === ActionType.EDIT ? "Modifier" : "Enregistrer"} 
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}

                {/**********DELETE ALERTE ****** */}
                {false && (
                    <Modal
                        title="Supprimer un partenaire !!!"
                        visible={false}
                        onOk={() => {}}
                        onCancel={() => {}}
                        okText="Confirmer"
                        cancelText="Annuler"
                    >
                        <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment ce partenaire ?</h3>
                    </Modal>
                )}
            </div>
        </Card>
    )
}