import { Form } from "antd";
import React from "react";
import NumberFormat from "react-number-format";

const PhoneField=({disable})=> {
    return (
        <React.Fragment>
             <Form.Item
                    label="Téléphone (mobile)"
                    name="assurePhone"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <NumberFormat 
                        placeholder="Téléphone"
                        format="+229 ##-##-##-##-##" 
                        mask="_"
                        className='ant-input  ant-input-lg'  
                        disabled={disable} />
                </Form.Item>
        </React.Fragment>
    )
}

export default PhoneField;